import React from "react"
import styled from "styled-components"

const PrintIconSVG = styled.svg`
  width: 50px;
  height: 50px;
  filter: none;
`

const PrintIconPath = styled.path`
  fill: #fff;
`

export default function PrintIcon() {
  return (
    <PrintIconSVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.05 6">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <PrintIconPath
            className="cls-1"
            d="M5.8,3.87a.45.45,0,1,0,0-.9.45.45,0,1,0,0,.9Zm0-.61a.16.16,0,0,1,0,.32.16.16,0,1,1,0-.32Zm-.88-.78a.18.18,0,0,1-.17-.17V.48L4.27,0H1A.17.17,0,0,0,.85.17V2.48H0V5.41H.85V6H6.2V5.41h.85V2.64a.16.16,0,0,0-.16-.16ZM1.15.46A.17.17,0,0,1,1.31.29H4.15L4.46.6V2.31a.18.18,0,0,1-.17.17h-3a.17.17,0,0,1-.16-.17ZM5.91,5.71H1.15V5H5.91Zm.85-.59H6.2V4.7H.85v.42H.3V2.77H6.59a.18.18,0,0,1,.17.17Z"
          />
        </g>
      </g>
    </PrintIconSVG>
  )
}
