import React, { Component, Fragment } from "react"

export const currencyFormat = value => {
  return `$${addCommas(Number(value).toFixed(2))}`
}

const addCommas = nStr => {
  nStr += ""
  let x = nStr.split(".")
  let x1 = x[0]
  let x2 = x.length > 1 ? "." + x[1] : ""
  let rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `$1,$2`)
  }
  return x1 + x2
}

class NumberFormat extends Component {
  render() {
    return (
      <Fragment>
        {`${this.props.currency ? "$" : ""}${addCommas(
          Number(this.props.value).toFixed(2)
        )}`}
      </Fragment>
    )
  }
}

export const formattedDate = value => {
  const dateObj = new Date(value.split(" ")[0])
  const dateStr =
    dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()
  const monthStr =
    dateObj.getMonth() + 1 < 10
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  return `${dateStr}/${monthStr}/${dateObj.getFullYear()}`
}

export const NumberFormat3Decimal = props => (
  <Fragment>
    {`${props.currency ? "$" : ""}${addCommas(Number(props.value).toFixed(3))}`}
  </Fragment>
)

export class NumberFormatNoCents extends Component {
  render() {
    return (
      <Fragment>
        {`${this.props.currency ? "$" : ""}${addCommas(
          Number(this.props.value).toFixed(0)
        )}`}
      </Fragment>
    )
  }
}

export default NumberFormat
