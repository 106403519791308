import React from "react"
import EgiftitLogo from "../assets/images/logo_landscape.png"
import EgiftitLogoLight from "../assets/images/logo_light.png"

export function LightLogoNew(props) {
  return (
    <div className="egiftit__logo-light">
      <a
        href={`${process.env.GATSBY_EGIFTIT_FRONTEND}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <img
          src={EgiftitLogoLight}
          alt={"eGift it Logo"}
          width="50"
          height="50"
        />
      </a>
    </div>
  )
}

export default props => {
  return (
    <div className="egiftit__logo">
      <a
        href={`${process.env.GATSBY_EGIFTIT_FRONTEND}`}
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        <img src={EgiftitLogo} alt={"eGift it Logo"} width="150" height="40" />
      </a>
    </div>
  )
}
